body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f7b6e8;
}

.container {
    align-items: center;
    text-align: center;
}

.birthdayCard {
    position: relative;
    width: 500px;
    height: 700px;
    cursor: pointer;
    transform-style: preserve-3d;
    transform: perspective(2500px);
    transition: 1s;
}

.birthdayCard:hover {
    transform: perspective(2500px) rotate(0deg);
    box-shadow: inset 100px 20px 100px rgba(0, 0, 0, 0.2),
        0 10px 100px rgba(0, 0, 0, 0.5);
}

.birthdayCard:hover .cardFront {
    transform: rotateY(-160deg);
}

.birthdayCard:hover .happy {
    visibility: hidden;
}

.cardFront {
    position: relative;
    background-color: #caf1fb;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform-origin: left;
    box-shadow: inset 10px 0px 10px rgba(0, 0, 0, 0.1),
        30px 0 50px rgba(0, 0, 0, 0.4);
    transition: 0.6s;
}

.happy {
    font-family: "Thonburi-Bold";
    text-align: center;
    font-size: 30px;
    margin: 30px;
    background-image: linear-gradient(120deg, #deafff 0%, #c573ff 100%);
    transition: 0.1s;
    backface-visibility: visible;
}

.balloons {
    position: absolute;
}

.balloonOne,
.balloonTwo,
.balloonThree,
.balloonFour {
    position: absolute;
    width: 170px;
    height: 180px;
    border-radius: 50%;
}

.balloonOne {
    background-color: rgb(239, 71, 111, 0.7);
    left: -20px;
    top: 100px;
}

.balloonTwo {
    background-color: rgb(6, 214, 160, 0.7);
    left: 100px;
    top: 40px;
}

.balloonThree {
    background-color: rgb(255, 209, 102, 0.7);
    left: 220px;
    top: 100px;
}

.balloonFour {
    background-color: rgb(17, 138, 178, 0.7);
    left: 340px;
    top: 40px;
}

.balloonOne:before,
.balloonTwo:before,
.balloonThree:before,
.balloonFour:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 310px;
    background-color: gray;
    top: 180px;
    left: 87px;
}

.balloonOne:after,
.balloonTwo:after,
.balloonThree:after,
.balloonFour:after {
    content: "";
    position: absolute;
    border-right: 14px solid transparent;
    border-left: 14px solid transparent;
    top: 180px;
    left: 74px;
}

.balloonOne:after {
    border-bottom: 20px solid #ef476f;
}

.balloonTwo:after {
    border-bottom: 20px solid #06d6a0;
}

.balloonThree:after {
    border-bottom: 20px solid #ffd166;
}

.balloonFour:after {
    border-bottom: 20px solid #118ab2;
}

.cardInside {
    position: absolute;
    background-color: #caf1fb;
    width: 100%;
    height: 100%;
    z-index: -1;
    left: 0;
    top: 0;
    box-shadow: inset 10px 0px 10px rgba(0, 0, 0, 0.1);
}

p {
    font-family: "Avenir", sans-serif;
    margin: 20px;
    margin-left: 40px;
    color: #333;
    font-size: 25px;
    text-align: left;
}

.name {
    position: absolute;
    left: 200px;
    top: 300px;
    color: #333;
}

.back {
    font-family: "Thonburi-Bold", sans-serif;
    color: #333;
    text-align: center;
    margin: 30px;
    outline-color: #333;
    outline-style: dotted;
}

@media (max-width: 500px) or (max-height: 775px) {
    .birthdayCard {
        width: 300px;
        height: 400px;
    }
    /* make balloons fit to screen size */
    .balloonOne,
    .balloonTwo,
    .balloonThree,
    .balloonFour {
        width: 85px;
        height: 90px;
    }

    .balloonOne {
        left: 10px;
        top: 50px;
    }

    .balloonTwo {
        left: 75px;
        top: 20px;
    }

    .balloonThree {
        left: 145px;
        top: 50px;
    }

    .balloonFour {
        left: 205px;
        top: 20px;
    }

    .balloonOne:before,
    .balloonTwo:before,
    .balloonThree:before,
    .balloonFour:before {
        width: 1px;
        height: 155px;
        top: 90px;
        left: 44px;
    }

    .balloonOne:after,
    .balloonTwo:after,
    .balloonThree:after,
    .balloonFour:after {
        top: 90px;
        left: 30px;
    }

    .balloonOne:after {
        border-bottom: 7px solid #ef476f;
    }

    .balloonTwo:after {
        border-bottom: 7px solid #06d6a0;
    }

    .balloonThree:after {
        border-bottom: 7px solid #ffd166;
    }

    .balloonFour:after {
        border-bottom: 7px solid #118ab2;
    }

    .name {
        position: absolute;
        left: 150px;
        top: 300px;
        color: #333;
    }

    p {
        margin: 7px;
        margin-left: 20px;
        color: #333;
        font-size: 15px;
    }

    .back {
        color: #333;
        text-align: center;
        margin: 20px;
        outline-color: #333;
        outline-style: dotted;
    }

    .happy {
        text-align: center;
        font-size: 20px;
        margin: 20px;
        background-image: linear-gradient(120deg, #deafff 0%, #c573ff 100%);
        transition: 0.1s;
        backface-visibility: visible;
    }
}
